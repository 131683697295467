import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import {
  getAdmindetails,
  shopsverifiactionlisting,
} from "../redux/Actions/AdminActions";
import Logo from "../Assets/Images/Profile-icon.png";
import { useDispatch, useSelector } from "react-redux";
import CommonHeader from "../Common/CommonHeader";
import PaginationComponet from "../Components/Pagination";

export default function ShopsVerification() {
  const shopslisting = useSelector(
    (state) => state.shopsverifications?.alldata
  );
  const url = process.env.REACT_APP_FILE_BASE_URL;

  console.log(shopslisting);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      shopsverifiactionlisting({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };
    dispatch(shopsverifiactionlisting(searchData));
  }, [page, limit, search, flag]);

  const handleNavigate = (id) => {
    navigate(`/shops-profile/${id}`);
  };

  return (
    <Layout>
      <Container fluid>
        <CommonHeader />
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Shop’s Verification</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
                {/* <div className="profile-status">
                  <div className="select-box">
                    <Form.Select aria-label="Default select example">
                      <option>Profile Status</option>
                      <option value="active">Dummy</option>
                    </Form.Select>
                  </div>
                </div> */}
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">S.No.</div>
                    </th>
                    <th>Profile picture</th>
                    <th>Shop name </th>
                    <th>Email </th>
                    <th>Profile Status</th>
                  </tr>
                </thead>
                <tbody>
                  {shopslisting?.users?.map((res, index) => {
                    const dynamicIndex = (page - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>
                          <p className="text-style">{dynamicIndex}</p>
                        </td>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">
                              {res?.profile_image ? (
                                <img src={`${url}${res?.profile_image}`} />
                              ) : (
                                <img src={Logo} />
                              )}
                             
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            className="user-role pointer-icon"
                            onClick={() => handleNavigate(res?._id)}
                          >
                            <p>{res?.name || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="shop-user-mail">
                            <p>{res?.email1 || "N/A"}</p>
                          </div>
                        </td>

                        <td>
                          <div
                            className={
                              res?.is_admin_verified === 0
                                ? "pending"
                                : "active"
                            }
                          >
                            <p>
                              {res?.is_admin_verified === 0
                                ? "Not verified"
                                : "Verified"}
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, shopslisting?.totalCount)} of{" "}
                    {shopslisting?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={page}
                    totalCount={shopslisting?.totalCount}
                    limit={shopslisting?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>
    </Layout>
  );
}
