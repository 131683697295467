import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal, Button } from "react-bootstrap";
import {
  DeleteUser,
  Suspenduser,
  getActivity,
  getAdmindetails,
  getuserprofile,
} from "../redux/Actions/AdminActions";
import Logo from "../Assets/Images/Profile-icon.png";
import { useDispatch } from "react-redux";
import { date } from "yup";
import { toast } from "react-toastify";
import CommonHeader from "../Common/CommonHeader";
import ImagePreviewModal from "../Common/ImagePreviewModal";

export default function UserProfile() {
  const navigate = useNavigate();

  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [UserDetails, setuserDetails] = useState("");
  console.log(UserDetails, "userdetailssssss");
  const [ActivityDetails, setActivityDetails] = useState([]);
  const [show, setshow] = useState(false);
  const [showd, setshowd] = useState(false);
  const [flag, setflag] = useState(true);

  const [showI, setShowI] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleClose = () => setShowI(false);
  const handleShow = () => setShowI(true);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getuserprofile({ userId: id }))
      .then((response) => {
        console.log(response, "User data");
        if (response?.payload?.data?.status === 200) {
          setuserDetails(response?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  useEffect(() => {
    const getactivitydata = async () => {
      const data = await dispatch(getActivity({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setActivityDetails(data?.payload?.data);
      }
    };
    getactivitydata();
  }, [id]);

  const handleDeleteUSer = () => {
    dispatch(DeleteUser({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
          navigate("/user");
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  const handleSuspend = async () => {
    const data = await dispatch(Suspenduser({ userId: id, type: 0 }));
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setshow(false);
      navigate("/user");
    }
  };

  const handlePrivewImagesOpen = (img) => {
    setImageModal(true);
    setSelectedImage(img);
  };

  const handlePrivewImagesClose = () => {
    setImageModal(false);
    setSelectedImage("");
  };

  return (
    <Layout>
      <Container fluid>
        <CommonHeader />
      </Container>
      <Container fluid>
        <div className="user-profile-page ">
          <div className="profile-info">
            <div className="left-side-profile">
              <div className="left-user-profile">
                <div className="user-profile-image">
                  {/* {UserDetails?.profile_image ? (
                    <img
                      src={`${url}${UserDetails.profile_image}`}
                      alt="Profile"
                    />
                  ) : (
                    <img src={Logo} alt="Placeholder Logo"/>
                  )} */}

                  {UserDetails?.profile_image &&
                  UserDetails?.profile_image !== null &&
                  UserDetails?.profile_image !== "" ? (
                    <img
                      src={`${url}${UserDetails.profile_image}`}
                      alt="Profile"
                      onClick={() =>
                        handlePrivewImagesOpen(UserDetails?.profile_image)
                      }
                    />
                  ) : (
                    <img src={Logo} />
                  )}
                </div>
                <div className="profile-input-field">
                  <div className="user-prof-info">
                    <h2>Profile info</h2>
                    <div className="input-field-profile">
                      <label>Profile name</label>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Profile name"
                          value={
                            UserDetails?.name
                              ? UserDetails?.name
                              : UserDetails?.shop_name || "N/A"
                          }
                        />
                      </Form.Group>
                    </div>

                    <div className="input-field-profile">
                      <label>Bio</label>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Type Here"
                          value={UserDetails?.about_me || "N/A"}
                        />
                      </Form.Group>
                    </div>

                    <div className="input-field-profile">
                      <label>Email</label>
                      <Form.Group>
                        <Form.Control
                          type="email"
                          placeholder="Profile name"
                          value={UserDetails?.email || "N/A"}
                        />
                      </Form.Group>
                    </div>

                    <div className="input-field-profile">
                      <label>Age</label>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          value={UserDetails?.age || "N/A"}
                        />
                      </Form.Group>
                    </div>
                    <div className="input-field-profile">
                      <label>Subscription Plan</label>
                      <Form.Group>
                        <Form.Control type="text" placeholder="" value="N/A" />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="user-prof-info">
                    <h2>Contact info</h2>
                    <div className="input-field-profile">
                      <label>Phone number</label>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          value={
                            UserDetails?.phone_number
                              ? UserDetails.country_code
                                ? `${UserDetails.country_code} ${UserDetails.phone_number}`
                                : `${UserDetails.phone_number}`
                              : "N/A"
                          }
                        />
                      </Form.Group>
                    </div>

                    <div className="input-field-profile">
                      <label>Address</label>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={UserDetails?.address}
                        />
                      </Form.Group>
                    </div>

                    <div className="input-field-profile">
                      <label>Email</label>
                      <Form.Group>
                        <Form.Control
                          type="email"
                          placeholder="Profile name"
                          value={UserDetails?.email}
                        />
                      </Form.Group>
                    </div>

                    <div className="input-field-profile">
                      <label>Country</label>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          value={UserDetails?.country}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="account-box mt-4 mb-5">
            <div className="profile-activity">
              <div className="inner-box-ps">
                <div className="heading-text-active">
                  <h2>Activity</h2>
                  {ActivityDetails?.map((details) => {
                    return (
                      <ul>
                        <li>
                          <h6>{details?.title}</h6>
                          <p>
                            {ActivityDetails && ActivityDetails.length > 0 ? (
                              <ul>
                                {ActivityDetails.map((details, index) => (
                                  <li key={index}>
                                    <h6>{details?.title}</h6>
                                    <p>
                                      {details?.createdAt
                                        ? new Date(details?.createdAt)
                                            .toISOString()
                                            .slice(0, 10)
                                            .replace(/-/g, "/")
                                        : "N/A"}
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <p>No activities happened yet</p>
                            )}
                          </p>
                        </li>
                      </ul>
                    );
                  })}
                </div>
              </div>

              <div className="inner-box-ps mt-4">
                <div className="heading-text-active">
                  <h2>Documents</h2>
                </div>

                <div className="identity-proof">
                  <p>Identity proof</p>
                  {UserDetails?.upload_id ? (
                    // Check if the uploaded document is a PDF or an image
                    UserDetails.upload_id.endsWith(".pdf") ? (
                      <a
                        href={`${url}${UserDetails.upload_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "blue",
                        }}
                      >
                        <p>View PDF Document Click here to open</p>
                      </a>
                    ) : UserDetails.upload_id.endsWith(".jpg") ||
                      UserDetails.upload_id.endsWith(".jpeg") ||
                      UserDetails.upload_id.endsWith(".png") ? (
                      <img
                        className="profile-add-img"
                        src={`${url}${UserDetails.upload_id}`}
                        alt="Licensed Document"
                        onClick={() =>
                          handlePrivewImagesOpen(UserDetails.upload_id)
                        }
                        style={{
                          cursor: "pointer",
                          width: "150px",
                          height: "150px",
                        }}
                      />
                    ) : (
                      <p>Unsupported file type</p>
                    )
                  ) : (
                    <p>Document not found</p>
                  )}
                </div>
              </div>
            </div>
            <div className="bottom-flex-box">
              <div className="delete-acc">
                <h2>Delete Account</h2>
                <p>
                  Once you delete your account, you can not retrieve the
                  account. Please be certain.
                </p>
                <button
                  className="delete-btn-pro"
                  onClick={() => setshowd(true)}
                >
                  Delete Account
                </button>
              </div>

              <div className=" mt-4 mb-5">
                <div className="delete-acc">
                  <h2>Suspend account</h2>
                  <p>
                    You will not be able to receive messages, notifications for
                    up to 24hours.
                  </p>
                  <button
                    className="delete-btn-pro"
                    onClick={() => setshow(true)}
                  >
                    Suspend Account
                  </button>
                </div>
              </div>
            </div>

            <ImagePreviewModal
              handlePrivewImagesClose={handlePrivewImagesClose}
              imageModal={imageModal}
              selectedImage={selectedImage}
            />
          </div>
        </div>
      </Container>
      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Suspend this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn conform-btn" onClick={handleSuspend}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setshow(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn conform-btn" onClick={handleDeleteUSer}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setshowd(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
