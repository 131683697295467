import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import {
  DeleteShopProduct,
  getAdmindetails,
  shopsproducts,
} from "../redux/Actions/AdminActions";
import Logo from "../Assets/Images/Profile-icon.png";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponet from "../Components/Pagination";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ShopsProducts() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const ProductsData = useSelector((state) => state.productShpos?.alldata);
  console.log(ProductsData);
  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [userid, setUserid] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      shopsproducts({
        page: pageNumber,
        limit: limit,
        id: id,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
      id: id,
    };
    dispatch(shopsproducts(searchData));
  }, [page, limit, search, flag]);

  const handlenavigate = () => {
    navigate(`/shops-management-profile/${id}`);
  };

  const handleDelproduct = () => {
    dispatch(DeleteShopProduct({ productId: userid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status == 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "Error occur");
      });
  };

  const navigatehandler = (id) => {
    navigate(`/product-details/${id}`)
  }

  const handlenavigate1 = () => {
    navigate(`/shops-products/${id}`)
  }

  

  return (
    <Layout>
      <Container fluid>
        <div className="user-profile-heading management-heading">
          <div>
          <div className="arrow-alt-back" onClick={handlenavigate}>
            <img
              src={require("../Assets/Images/arrow_alt_lright.svg").default}
            />
          <h2>Shop Products</h2>
          </div>
          </div>

           <div className="new-profile-btn-action">
            <button onClick={handlenavigate}>
              Overview
            </button>
            <button onClick={handlenavigate1}>
              Shop Products
            </button>
          </div>
        </div>

        <div className="filters">
          <div className="inner-filter-field">
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search "
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">S.No.</div>
                    </th>
                    <th>Product name</th>
                    <th>Product Category </th>
                    <th>Image </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {ProductsData?.products?.map((res, index) => {
                    const dynamicIndex = (page - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>
                          <p className="text-style">{dynamicIndex}</p>
                        </td>
                        <td>
                          <div
                            className="user-role pointer-icon"
                            onClick={() => navigatehandler(res?._id)}
                          >
                            <p>{res?.product_name || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="shop-user-mail">
                            <p>{res?.product_category?.name || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">
                              <img
                                src={res?.image ? `${url}${res?.image}` : Logo}
                                style={{ borderRadius: 50 }}
                              />
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="pending">
                            <p
                              onClick={() => {
                                setshowd(true);
                                setUserid(res?._id);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Remove
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, ProductsData?.totalCount)} of{" "}
                    {ProductsData?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={page}
                    totalCount={ProductsData?.totalCount}
                    limit={ProductsData?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to remove this product?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn conform-btn" onClick={handleDelproduct}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setshowd(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
