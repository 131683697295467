import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { Form as BootstrapForm, Toast } from "react-bootstrap";
import { Addprormocode, getshops } from "../redux/Actions/AdminActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  promo_code: Yup.string().required("Promo code is required"),
  discount: Yup.number()
    .typeError("Discount value must be a number")
    .required("Discount value is required"),
  desc: Yup.string().required("Description is required"),
  limit: Yup.number()
    .required("Usage limit is required")
    .positive("Usage limit must be a positive number")
    .integer("Usage limit must be an integer"),
  min_order: Yup.number()
    .typeError("Minimum order value must be a number")
    .required("Minimum order value is required"),
  eligible: Yup.array()
    .min(1, "Customer eligibility is required")
    .required("Customer eligibility is required"),
  is_all_shop: Yup.string().required("Shop selection is required"),
  expiry_date: Yup.date().required("Expiry date is required"),
  is_active: Yup.boolean().oneOf([true], "Status is required"),
});

const usersTypes = [
  { label: "First time user", key: "firstTime" },
  { label: "All user’s", key: "allTime" },
  { label: "User’s with Locker number", key: "lockerUser" },
];

export default function AddPromoCode() {
  const [shops, setShops] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialvalues = {
    promo_code: "",
    discount: "",
    desc: "",
    limit: "",
    min_order: "",
    eligible: [],
    is_all_shop: "",
    expiry_date: "",
    is_active: false,
  };

  useEffect(() => {
    dispatch(getshops())
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setShops(res?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  const handleAddPromo = (values) => {
    const isAllShop = values?.is_all_shop === "1";
  
    const payload = {
      promo_code: values?.promo_code,
      discount: values?.discount,
      desc: values?.desc,
      limit: values?.limit,
      min_order: values?.min_order,
      eligible: values?.eligible,
      is_all_shop: isAllShop ? values?.is_all_shop : null,
      shopId: isAllShop ? null : values?.is_all_shop,
      expiry_date: values?.expiry_date,
      is_active: values?.is_active == true ? 1 : 0,
    };
  
    dispatch(Addprormocode(payload))
      .then((res) => {
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          navigate("/manage-promo-code");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  return (
    <Layout>
      <Container fluid>
        <div className="user-profile-heading ">
          <div
            className="arrow-alt-back"
            onClick={() => navigate("/manage-promo-code")}
          >
            <img
              src={require("../Assets/Images/arrow_alt_lright.svg").default}
            />
          </div>
          <h2>Add Promo-code </h2>
        </div>

        <Formik
          initialValues={initialvalues}
          validationSchema={validationSchema}
          onSubmit={(value) => handleAddPromo(value)}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <Form>
              <div className="filters pb-2 pt-3">
                <div className="create-notification-page">
                  <div className="category-field">
                    <div className="modal-input ">
                      <label>Promo code</label>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Type here "
                          name="promo_code"
                          onChange={handleChange}
                          value={values?.promo_code}
                        />
                        <ErrorMessage
                          name="promo_code"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>
                    </div>
                    <div className="modal-input ">
                      <label>Discount Value(Only numbers)</label>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Type here"
                          name="discount"
                          onChange={handleChange}
                          value={values?.discount}
                        />
                        <ErrorMessage
                          name="discount"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="textarea-input">
                    <Form.Group className="mt-4">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Type here"
                        name="desc"
                        onChange={handleChange}
                        value={values?.desc}
                      />
                      <ErrorMessage
                        name="desc"
                        className="error-message"
                        component="div"
                      />
                    </Form.Group>
                  </div>

                  <div className="category-field mt-4">
                    <div className="input-select">
                      <label>Usage limit</label>
                      <Form.Select
                        aria-label="Default select example"
                        name="limit"
                        value={values?.limit}
                        onChange={handleChange}
                      >
                        <option> Select </option>
                        <option value="1">24</option>
                        <option value="2">25</option>
                        <option value="3">26</option>
                      </Form.Select>
                      <ErrorMessage
                        name="limit"
                        className="error-message"
                        component="div"
                      />
                    </div>
                    <div className="modal-input ">
                      <label>Minimum order value</label>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="Type here "
                          name="min_order"
                          onChange={handleChange}
                          value={values?.min_order}
                        />
                        <ErrorMessage
                          name="min_order"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="customer-option-box mt-5">
                    <div className="modal-input">
                      <label>Customer Eligibility</label>

                      {usersTypes.map((ele, index) => (
                        <div className="radio-btn-op mt-3" key={index}>
                          <div className="user-time">
                            <p>{ele?.label}</p>
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <Field
                              type="checkbox"
                              name="eligible"
                              value={index} // Use index to store the selected value
                              onChange={() => {
                                const eligible = [...values.eligible];
                                if (eligible.includes(index)) {
                                  setFieldValue(
                                    "eligible",
                                    eligible.filter((item) => item !== index)
                                  );
                                } else {
                                  setFieldValue("eligible", [
                                    ...eligible,
                                    index,
                                  ]);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="select-shop">
                      <div className="input-select ">
                        <label>Select Shop</label>
                        <Form.Select
                          aria-label="Default select example"
                          className=" mt-2"
                          name="is_all_shop"
                          value={values?.is_all_shop}
                          onChange={handleChange}
                        >
                          <option value=""> Select </option>
                          <option value="1"> All Shops </option>
                          {shops.map((shop) => {
                            return (
                              <>
                                <option key={shop.id} value={shop._id}>
                                  {shop.shop_name}
                                </option>
                              </>
                            );
                          })}
                        </Form.Select>
                      </div>
                      <ErrorMessage
                        name="is_all_shop"
                        className="error-message"
                        component="div"
                      />
                      <div className=" mt-2">
                        <label>Set Expiry Date</label>
                        <div className="radio-btn-op ">
                          <div className="user-time">
                            <Field type="date" name="expiry_date" />
                          </div>
                        </div>
                      </div>
                      <ErrorMessage
                        name="expiry_date"
                        className="error-message"
                        component="div"
                      />

                      <div className=" mt-2">
                        <label>Status</label>
                        <div className="radio-btn-op ">
                          <div className="user-time">
                            <p>Active</p>
                          </div>
                          <div
                            className="switch-custom"
                            style={{ marginLeft: "10px" }}
                          >
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              name="is_active"
                              onChange={handleChange}
                              checked={values.is_active}
                            />
                           
                          </div>
                        </div>
                        <ErrorMessage
                              name="is_active"
                              className="error-message"
                              component="div"
                            />
                      </div>
                     
                    </div>
                 
                  </div>
                 
                  <ErrorMessage
                    name="eligible"
                    className="error-message"
                    component="div"
                  />
    
                  <div className="create-btn">
                    <button type="submit" onClick={handleSubmit}>
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>PIXINVENT</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
