import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";

export default function ChatSupport() {
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <div className="right-top-bar">
            <div className="inner-page-lable">
              <Link to="/support">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M3 8.00008L1.93934 9.06074L0.87868 8.00008L1.93934 6.93942L3 8.00008ZM23 6.50008C23.8284 6.50008 24.5 7.17165 24.5 8.00008C24.5 8.82851 23.8284 9.50008 23 9.50008V6.50008ZM8.60601 15.7274L1.93934 9.06074L4.06066 6.93942L10.7273 13.6061L8.60601 15.7274ZM1.93934 6.93942L8.60601 0.272755L10.7273 2.39408L4.06066 9.06074L1.93934 6.93942ZM3 6.50008L23 6.50008V9.50008L3 9.50008L3 6.50008Z"
                    fill="#071523"
                  />
                </svg>
                Support Chat
              </Link>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="support-chat-box-main">
              <div className="support-inner">
                <div className="support-msg-box">
                  <div className="user-box">
                    <div className="user-top-hdng">
                      <img src={require("../Assets/Images/user.png")} />
                      <h3>name</h3>
                      <h4>08:13 pm</h4>
                    </div>
                    <div className="user-msg-box">
                      <p>Hey I'm John Smith</p>
                    </div>
                  </div>
                </div>
              </div>
              <form>
                <div class="send-box">
                  <div class="mb-3 input-group">
                    <input
                      placeholder="Type your message here..."
                      aria-label="send"
                      aria-describedby="basic-addon2"
                      name="message"
                      type="text"
                      class="send-feild form-control"
                    />
                    <div className="share-btn">
                      {/* <img
                        className="spicker"
                        src={
                          require("../Assets/Images/spicker-IconButton.svg")
                            .default
                        }
                      />
                      <img
                        className="share-op"
                        src={
                          require("../Assets/Images/share-btn-icon.svg").default
                        }
                      /> */}
                    </div>
                    <button
                      class="input-group-text"
                      id="basic-addon2"
                      type="submit"
                    >
                      Send
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M6.66406 9.33333L13.9974 2"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.003 2L9.66964 14C9.60991 14.1303 9.47969 14.2139 9.33631 14.2139C9.19293 14.2139 9.06271 14.1303 9.00298 14L6.66964 9.33333L2.00298 7C1.87263 6.94027 1.78906 6.81004 1.78906 6.66667C1.78906 6.52329 1.87263 6.39306 2.00298 6.33333L14.003 2"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>PIXINVENT</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
