import { createSlice } from "@reduxjs/toolkit";

import { Educationmanagemet } from "../Actions/AdminActions";


export const EducationSlice = createSlice({
    name: "EducationSlice",
    initialState:{
        education: "",
    },
    reducers: {
          
    },

    extraReducers: (builder) => {
        
        builder.addCase(Educationmanagemet.fulfilled, (state, action) => {
            state.education = action.payload.data.data;
            
        });
    }
})


export default EducationSlice.reducer;