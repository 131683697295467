import React from 'react';
import { Modal } from 'react-bootstrap';
import { RxCross1 } from "react-icons/rx";

const ImagePreviewModal = ({handlePrivewImagesClose, imageModal,selectedImage}) => {
    let url = process.env.REACT_APP_FILE_BASE_URL;
  return (
    <Modal show={imageModal} onHide={handlePrivewImagesClose} className='pop-trans-set'
    dialogClassName="modal-90w"
    size="lg"
    aria-labelledby="example-custom-modal-styling-title"
    >
   <div className='img-show-pop'>
   <div>
   {/* <button  onClick={handlePrivewImagesClose}>Close</button> */}
   <RxCross1   onClick={handlePrivewImagesClose} />

   </div>
   <img   src={`${url}${selectedImage}`}/>



   </div>
  </Modal>
  )
}

export default ImagePreviewModal