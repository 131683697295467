import { createSlice } from "@reduxjs/toolkit";

import { Shopsmanagement } from "../Actions/AdminActions";


export const shopsmanageSlice = createSlice({
    name: "shopsmanageSlice",
    initialState:{
        alldata: "",
    },
    reducers: {
          
    },

    extraReducers: (builder) => {
        
        builder.addCase(Shopsmanagement.fulfilled, (state, action) => {
            state.alldata = action.payload.data.data;
            
        });
    }
})


export default shopsmanageSlice.reducer;