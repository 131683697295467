import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import * as yup from "yup";
import Logo from "../Assets/Images/Profile-icon.png";
import { Formik, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import {
  chnagePassword,
  editAdminprofile,
  getAdmindetails,
} from "../redux/Actions/AdminActions";
import { toast } from "react-toastify";
import Countrycode from "../Countrycode.json";

// validation schema for chnage password

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required("Required"),
  newPassword: yup
    .string()
    .min(8, "New password must be at least 8 characters long")
    .matches(
      /[a-z]/,

      "Must contain at least one lowercase letter"
    )
    .matches(
      /[A-Z]/,

      "  Must contain at least one uppercase letter"
    )
    .matches(/[0-9]/, " Must contain at least one number")
    .matches(
      /[\W_]/,

      "Must contain at least one special character"
    )
    .required("Required"),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Required"),
});

// validation schema for admin edit details

const validationschemaforEditdetails = yup.object({
  admin_name: yup.string().required("Required"),

  last_name: yup.string().required("Required"),

  email: yup.string().email("Invalid email address").required("Required"),

  phone_number: yup
    .string()
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    .required("Required"),
});

export default function MyProfile() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [admindetails, setAdminDetails] = useState({});
  const [imagePreview, setImagePreview] = useState("");
  const [curruntImage, setCurruntImage] = useState("");
  const [flag, setflag] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAdmindetails())
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.data?.status === 200) {
          setCurruntImage(response?.payload?.data?.data?.profile_image);

          setAdminDetails(response?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  // initial values for chnage password

  const initialvalues = {
    oldPassword: "",
    newPassword: "",
    confirmpassword: "",
  };

  const handleChangepassword = (values, resetForm) => {
    dispatch(
      chnagePassword({
        oldPassword: values?.oldPassword,
        newPassword: values?.newPassword,
      })
    )
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);

          setTimeout(() => {
            toast.dismiss();
            window.location.href = "/";
          }, 1500);

          resetForm();
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "err occur");
      });
  };

  // initailValues for edit profile

  const initialvaluesEditdetails = {
    admin_name: "",
    last_name: "",
    email: "",
    country_code: "",
    phone_number: "",
    profile_image: null,
  };

  // handle edit prfile function
  const handleProfileedit = (values, resetForm) => {
    const formData = new FormData();
    formData.append("admin_name", values.admin_name);
    formData.append("last_name", values.last_name);
    formData.append("email", values.email);
    formData.append("country_code", values.country_code);
    formData.append("phone_number", values.phone_number);
    if (values.profile_image) {
      formData.append("profile_image", values.profile_image);
    }

    dispatch(editAdminprofile(formData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setAdminDetails((prevState) => ({
            ...prevState,
            ...res.payload.data.data, // Update the state with new data
          }));
          toast.success(res?.payload?.data?.message);
          resetForm();
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "err occur");
      });
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setCurruntImage("");
      setFieldValue("profile_image", file);
      const url = URL.createObjectURL(file);
      setImagePreview(url);
    }
  };

  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <div className="right-top-bar">
            <div className="left-side-bar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M19 19L13 13"
                  stroke="#2F2B3D"
                  stroke-opacity="0.9"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="7"
                  stroke="#2F2B3D"
                  stroke-opacity="0.9"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <input type="tetx" placeholder="Search " />
            </div>
            <div className="right-side-bar">
              <div className="notify-icon">
                <div className="notification-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C16.3402 6.10655 17.8786 8.41425 18 11V14C18.1526 15.2608 18.8949 16.3742 20 17H4C5.10511 16.3742 5.84739 15.2608 6 14V11C6.12137 8.41425 7.65983 6.10655 10 5"
                      stroke="#2F2B3D"
                      stroke-opacity="0.9"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 17V18C9 19.6569 10.3431 21 12 21C13.6569 21 15 19.6569 15 18V17"
                      stroke="#2F2B3D"
                      stroke-opacity="0.9"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                 
                </div>
              </div>
              <div className="user-img-top">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {/* {curruntImage ? (
                      <img src={`${url}${curruntImage}`} />
                    ) : (
                      Logo
                    )} */}

                    {admindetails && admindetails?.profile_image ? (
                      <img
                        src={`${url}${admindetails?.profile_image}`}
                        alt="Profile"
                      />
                    ) : (
                      Logo
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item className="">
                      <div className="drop-pro-view">
                        {admindetails && admindetails?.admin_image ? (
                          <img
                            src={`${url}/public/uploads/${admindetails?.admin_image}`}
                            alt="Profile"
                          />
                        ) : (
                          Logo
                        )}

                        <div className="admin-name">
                          <h3>{admindetails?.admin_name}</h3>
                          <p>Admin</p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <div className="my-profile">
                      <Dropdown.Item href="#/action-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <ellipse
                            cx="10.9999"
                            cy="6.41667"
                            rx="3.66667"
                            ry="3.66667"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                        My Profile
                      </Dropdown.Item>
                    </div>

                    <Dropdown.Item
                      href="/"
                      className="logout-btn"
                      onClick={() => {
                        sessionStorage.clear();
                      }}
                    >
                      Logout
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M3.41659 12.8333C3.26188 12.8333 3.1135 12.7718 3.00411 12.6624C2.89471 12.553 2.83325 12.4047 2.83325 12.25V1.74996C2.83325 1.59525 2.89471 1.44688 3.00411 1.33748C3.1135 1.22808 3.26188 1.16663 3.41659 1.16663H11.5833C11.738 1.16663 11.8863 1.22808 11.9957 1.33748C12.1051 1.44688 12.1666 1.59525 12.1666 1.74996V3.49996H10.9999V2.33329H3.99992V11.6666H10.9999V10.5H12.1666V12.25C12.1666 12.4047 12.1051 12.553 11.9957 12.6624C11.8863 12.7718 11.738 12.8333 11.5833 12.8333H3.41659ZM10.9999 9.33329V7.58329H6.91659V6.41663H10.9999V4.66663L13.9166 6.99996L10.9999 9.33329Z"
                          fill="white"
                        />
                      </svg>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container fluid>
        <div className="my-profile-page mt-4">
          <Formik
            initialValues={admindetails || initialvaluesEditdetails}
            enableReinitialize={true}
            validationSchema={validationschemaforEditdetails}
            onSubmit={(values, { resetForm }) =>
              handleProfileedit(values, resetForm)
            }
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="profile-top-box">
                  <div className="photo-upload">
                    <div className="profile-picture">
                      <>
                        {curruntImage &&
                          curruntImage !== "" &&
                          curruntImage.includes("public") && (
                            <img
                              src={`${url}${curruntImage}`}
                              alt="Profilesss"
                            />
                          )}
                        {imagePreview && (
                          <img src={imagePreview} alt="Profile"/>
                        )}
                      </>
                    </div>
                    <div className="upload-box">
                      <div className="btn-up">
                        <input
                          id="profileImage"
                          name="profile_image"
                          type="file"
                          accept=".jpg, .jpeg, .png, .gif"
                          onChange={(event) =>
                            handleFileChange(event, setFieldValue)
                          }
                          style={{ display: "none" }}
                        />

                        <button
                          type="button"
                          onClick={() =>
                            document.getElementById("profileImage").click()
                          }
                          className="btn-upload"
                        >
                          Upload New Photo
                        </button>
                        <div className="reset-box">
                          <p
                            onClick={() => resetForm()}
                            style={{ cursor: "pointer" }}
                          >
                            Reset
                          </p>
                        </div>
                      </div>
                      <p className="image-disp">
                        Allowed JPG, GIF or PNG. Max size of 800Kb
                      </p>
                    </div>
                  </div>

                  <div className="profile-disp mt-5">
                    <div className="form-set input-profile">
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="type"
                          placeholder="First Name"
                          name="admin_name"
                          value={values?.admin_name}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          className="error-message"
                          name="admin_name"
                          component="div"
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="type"
                          placeholder="Last Name"
                          name="last_name"
                          value={values?.last_name}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          className="error-message"
                          name="last_name"
                          component="div"
                        />
                      </Form.Group>
                    </div>

                    {/* <div className="form-set input-profile mt-2">
                <Form.Group
                  className="mb-3 input-field-width"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Email" />
                </Form.Group>

                <Form.Group
                  className="mb-3 input-field-width"
                  controlId="formBasicPassword"
                >
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="number" placeholder="Phone Number"/>
                </Form.Group>
                
              </div> */}

                    <div className="form-set input-profile mt-2">
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={values?.email}
                          onChange={handleChange}
                          readOnly
                        />
                        <ErrorMessage
                          className="error-message"
                          name="email"
                          component="div"
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicPhone"
                      >
                        <Form.Label>Phone Number</Form.Label>
                        <div className="d-flex">
                          <Form.Select
                            className="me-2"
                            style={{ maxWidth: "120px" }}
                            name="country_code"
                            value={values?.country_code}
                            onChange={(e) =>
                              setFieldValue("country_code", e.target.value)
                            }
                          >
                            {Countrycode?.map(({ dial_code, name, flag }) => (
                              <option key={dial_code} value={dial_code}>
                                {flag} {dial_code}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control
                            type="tel"
                            placeholder="Phone Number"
                            name="phone_number"
                            value={values?.phone_number}
                            onChange={handleChange}
                          />
                        </div>
                        <ErrorMessage
                          className="error-message"
                          name="phone_number"
                          component="div"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="btn-up mt-2">
                    <button type="submit">Save Changes</button>
                    <div className="reset-box">
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/user");
                        }}
                      >
                        Cancel
                      </p>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          <Formik
            initialValues={initialvalues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) =>
              handleChangepassword(values, resetForm)
            }
          >
            {({ values, handleChange, handleSubmit, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="profile-top-box mt-4">
                  <div className="heading-profile">
                    <h2>Change Password</h2>
                  </div>

                  <div className="profile-disp mt-4">
                    <div className="form-set input-profile">
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Current Password"
                          name="oldPassword"
                          value={values?.oldPassword}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="oldPassword"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>
                    </div>

                    <div className="form-set input-profile mt-2">
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="New Password"
                          name="newPassword"
                          value={values?.newPassword}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="newPassword"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Confirm New Password"
                          name="confirmpassword"
                          value={values?.confirmpassword}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="confirmpassword"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="password-req mt-3 mb-4">
                    <h4>Password Requirements: </h4>
                    <p>
                      <span>
                        <img
                          src={require("../Assets/Images/bullets.svg").default}
                        />
                      </span>
                      Minimum 8 characters long - the more, the better
                    </p>
                    <p>
                      <span>
                        <img
                          src={require("../Assets/Images/bullets.svg").default}
                        />
                      </span>
                      At least one lowercase character
                    </p>
                    <p>
                      <span>
                        <img
                          src={require("../Assets/Images/bullets.svg").default}
                        />
                      </span>
                      At least one number, symbol, or whitespace character
                    </p>
                  </div>

                  <div className="btn-up mt-2">
                    <button type="submit">Save Changes</button>
                    <div className="reset-box">
                      <p
                        onClick={() => resetForm()}
                        style={{ cursor: "pointer" }}
                      >
                        Reset
                      </p>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>
    </Layout>
  );
}
