import { createSlice } from "@reduxjs/toolkit";
import { Managepromocode } from "../Actions/AdminActions";

export const PromoSlice = createSlice({
    name: "PromoSlice",
    initialState:{
        alldata: "",
    },
    reducers: {
          
    },

    extraReducers: (builder) => {
        
        builder.addCase(Managepromocode.fulfilled, (state, action) => {
            state.alldata = action.payload.data.data;
            
        });
    }
    
})


export default PromoSlice.reducer;