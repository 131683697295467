import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";


export default function ProductOverview() {
  const navigate = useNavigate();
  const [ActivityDetails, setActivityDetails] = useState([]);

  const {id} = useParams();


  const handlenavigate  = () => {
    navigate(`/shops-management-profile/${id}`)
  }

  
  return (
    <Layout>
      <Container fluid>
        <div className="user-profile-heading ">
          <div
            className="arrow-alt-back"
            onClick={handlenavigate}
          >
            <img
              src={require("../Assets/Images/arrow_alt_lright.svg").default}
            />
          </div>
          <h2>Product Overview</h2>
        </div>

        <div className="shop-profile-info">
          <div className="shop-profile-pic">
            <img
              src={require("../Assets/Images/profile-picture.svg").default}
            />
          </div>
          <div className="profile-management-field">
            <div className="user-management-prof-info">
              <h2>Profile info</h2>
              <div className="use-grid-system">
                <div className="input-field-profile">
                  <label>Product category</label>
                  <Form.Group>
                    <Form.Control type="text" placeholder="Dummy" />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Product name</label>
                  <Form.Group>
                    <Form.Control type="email" placeholder="Dummy" />
                  </Form.Group>
                </div>

                <div className="input-field-profile">
                  <label>Price</label>
                  <Form.Group>
                    <Form.Control type="text" placeholder="Dummy" />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Stock count</label>
                  <Form.Group>
                    <Form.Control type="text" placeholder="Dummy" />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
