import { configureStore } from "@reduxjs/toolkit";
import { usermanageSlice } from "./Reducer/UsermgmtSlice"
import { PromoSlice } from "./Reducer/PromoMgmtSlice";
import { shopsverifiactionSlice } from "./Reducer/ShopsverificationSlice";
import { shopsmanageSlice } from "./Reducer/Shopsmgmt";
import { shopsSlice } from "./Reducer/ShopsProductsSlice";
import { ordersmgmtSlice } from "./Reducer/OrderManagementSlice";
import { EducationSlice } from "./Reducer/EducationManageSlice";

export const Store = configureStore({
  reducer: {
   usermgt:usermanageSlice.reducer,
   Promo:PromoSlice.reducer,
   shopsverifications:shopsverifiactionSlice.reducer,
   shopsmgmt:shopsmanageSlice.reducer,
   productShpos:shopsSlice.reducer,
   ordermgmt:ordersmgmtSlice.reducer,
   educationmgmt:EducationSlice.reducer
  },
});
