import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../services/AdminApi";

// ====== For Usermanagement =====

export const usermanagement = createAsyncThunk(
  "usermanagement",
  async (details) => {
    let url = `/userManagement?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// =======  Dowanload Users List ============

export const DowanloadUserxlsv = createAsyncThunk(
  "DowanloadUserxlsv",
  async () => {
    const data = await AdminAPI.get(`/userManagement`);
    console.log(data);
    return data;
  }
);

// ======== Delete User account api =============

export const DeleteUser = createAsyncThunk("DeleteUser", async (details) => {
  const data = await AdminAPI.delete(`/deleteAccount?userId=${details?.id}`);
  return data;
});

// ===== Forget Password =======

export const forgetPass = createAsyncThunk("forgetPass", async (details) => {
  const data = await AdminAPI.post(`/forgetpassword`, details);
  return data;
});

// ======= Reset Password ===========

export const ResetPass = createAsyncThunk("ResetPass", async (details) => {
  const data = await AdminAPI.post(`/resetpassword`, details);
  return data;
});

// ==================== change Password ======================

export const chnagePassword = createAsyncThunk(
  "chnagePassword",
  async (details) => {
    const data = await AdminAPI.patch(`/changePassword`, details);
    console.log(data);
    return data;
  }
);

// ==================== edit admin profile ======================

export const editAdminprofile = createAsyncThunk(
  "editAdminprofile",
  async (details) => {
    const data = await AdminAPI.put(`/editProfile`, details);
    console.log(data);
    return data;
  }
);

// ==== get admin details to edit profile ====
export const getAdmindetails = createAsyncThunk("getAdmindetails", async () => {
  const data = await AdminAPI.get(`/getProfile`);
  console.log(data);
  return data;
});

// ===== Get user Profile =====

export const getuserprofile = createAsyncThunk(
  "getuserprofile",
  async (details) => {
    const data = await AdminAPI.get(`/userProfile?userId=${details?.userId}`);
    console.log(data);
    return data;
  }
);

// ================ get user activity ===================

export const getActivity = createAsyncThunk("getActivity", async (details) => {
  const { data } = await AdminAPI.get(`/getActivities?user_id=${details?.id}`);
  return data;
});

// =============== Activate or deactivate user =================

export const Suspenduser = createAsyncThunk("Suspenduser", async (details) => {
  const data = await AdminAPI.put(`/activateDeactivateUser`, details);
  console.log(data);
  return data;
});

// ===========  Promocode listing management  ============

export const Managepromocode = createAsyncThunk(
  "ManagePromoCode",
  async (details) => {
    let url = `/getPromocode?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

//  ===============    Add Promocode  ============

export const Addprormocode = createAsyncThunk(
  "Addprormocode",
  async (details) => {
    const data = await AdminAPI.post(`/addPromoCode`, details);
    console.log(data);
    return data;
  }
);

//  ============= Edit Promocode ===========

export const Editpromocode = createAsyncThunk(
  "Editpromocode",
  async (details) => {
    const data = await AdminAPI.put(`/editPromoCode`, details);
    console.log(data);
    return data;
  }
);

// ==========  get Promocode by id  ===========

export const getPromodetail = createAsyncThunk(
  "getPromodetail",
  async (details) => {
    const { data } = await AdminAPI.get(`/getPromoById?promoId=${details?.id}`);
    return data;
  }
);

// =========== Delete Promocode ============

export const DeletePromo = createAsyncThunk("DeletePromo", async (details) => {
  const { data } = await AdminAPI.put(`/delPromo`, details);
  return data;
});

// Get Shops api
export const getshops = createAsyncThunk("getshops", async () => {
  const data = await AdminAPI.get(`/getOwner`);
  return data;
});

// Add Product With  Type

export const Addproduct = createAsyncThunk("Addproduct", async (details) => {
  const { data } = await AdminAPI.post(`/addProduct`, details);
  return data;
});

// gat all Products

export const getProducts = createAsyncThunk("getProducts", async (details) => {
  let url = `/getProducts?page=${details?.page}&limit=${details?.limit}`;

  if (details.search) {
    url += `&name=${details.search}`;
  }
  const data = await AdminAPI.get(url);
  console.log(data);
  return data;
});

// =========== Delete Product ============

export const DeleteProduct = createAsyncThunk(
  "DeleteProduct",
  async (details) => {
    const { data } = await AdminAPI.put(`/delProduct`, details);
    return data;
  }
);

// manage shop verification

export const shopsverifiactionlisting = createAsyncThunk(
  "shopsverifiactionlisting",
  async (details) => {
    let url = `/verificationManagement?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }
    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

//  User Profile in shop verification detail

export const getshopdetail = createAsyncThunk(
  "getshopdetail",
  async (details) => {
    const { data } = await AdminAPI.get(`/userProfile?userId=${details?.id}`);
    return data;
  }
);

// Verify Shop

export const VerifyShop = createAsyncThunk("VerifyShop", async (details) => {
  const { data } = await AdminAPI.put(`/verifyUser`, details);
  return data;
});

// shops management

export const Shopsmanagement = createAsyncThunk(
  "Shopsmanagement",
  async (details) => {
    let url = `/shopManagement?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }
    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// Shops Products Listing

export const shopsproducts = createAsyncThunk(
  "shopsproducts",
  async (details) => {
    let url = `/shopProduct?userId=${details?.id}&page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// delete Shop products

export const DeleteShopProduct = createAsyncThunk(
  "DeleteShopProduct",
  async (details) => {
    const { data } = await AdminAPI.put(`/delShopProduct`, details);
    return data;
  }
);

// get shop product detal by id
export const getshopProductdetail = createAsyncThunk(
  "getshopProductdetail",
  async (details) => {
    const { data } = await AdminAPI.get(
      `/getShopDetail?productId=${details?.id}`
    );
    return data;
  }
);

// Order management
export const orderManagement = createAsyncThunk(
  "orderManagement",
  async (details) => {
    let url = `/orderManagement?type=${details?.type}&page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// Implemnt Api of get Orders detail

export const getOrdersDetail = createAsyncThunk(
  "getOrdersDetail",
  async (details) => {
    const { data } = await AdminAPI.get(
      `/getOrderById?orderId=${details?.id}`
    );
    return data;
  }
);

// Education Management 

export const Educationmanagemet = createAsyncThunk(
  "Educationmanagemet",
  async (details) => {
    let url = `/educationManagement?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

//  Delete Course

export const DeleteCourse = createAsyncThunk(
  "DeleteCourse",
  async (details) => {
    const { data } = await AdminAPI.put(`/delEducation`, details);
    return data;
  }
);

// Get Course detail by id

export const getCourseDetail = createAsyncThunk(
  "getCourseDetail",
  async (details) => {
    const { data } = await AdminAPI.get(
      `/educationDetails?courseId=${details?.id}`
    );
    return data;
  }
);


