import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import {
  getAdmindetails,
  orderManagement,
} from "../redux/Actions/AdminActions";
import Logo from "../Assets/Images/Profile-icon.png";
import { useDispatch, useSelector } from "react-redux";
import CommonHeader from "../Common/CommonHeader";
import PaginationComponet from "../Components/Pagination";
import momonet from "moment";

export default function OrderManagement() {
  const ordersData = useSelector((state) => state.ordermgmt?.orders);
  console.log(ordersData);
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [activeToggle, setActiveToggle] = useState("images");
  const [type, settype] = useState("");
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      orderManagement({
        page: pageNumber,
        limit: limit,
        type:type
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
      type:type
    };
    dispatch(orderManagement(searchData));
  }, [page, limit, search, flag,type]);


  const handlenavigate = (id) => {
    navigate(`/order-details/${id}`)
  }

  return (
    <Layout>
      <Container fluid>
        <CommonHeader/>
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Order Management</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div className="user-search-passengers">
              <div className="drop-down">
                <div className="toggle-box-top">
                  {/* one toggle (Images) */}
                  <div
                    className={`toggle-btn ${
                      activeToggle === "images" ? "toggle-active" : ""
                    }`}
                    onClick={() => {
                      setActiveToggle("images");
                      
                    }} // Set active state to 'images' on click
                  >
                    <p>All</p>
                  </div>

                  {/* two toggle (HEIC To JPG) */}
                  <div
                    className={`toggle-btn ${
                      activeToggle === "heicToJpg" ? "toggle-active" : ""
                    }`}
                    onClick={() => {
                      setActiveToggle("heicToJpg");
                      settype("1");
                    }} // Set active state to 'heicToJpg' on click
                  >
                    <p>Accepted</p>
                  </div>
                  {/* Three toggle (HEIC To JPG) */}
                  <div
                    className={`toggle-btn ${
                      activeToggle === "three" ? "toggle-active" : ""
                    }`}
                    onClick={() => {
                      setActiveToggle("three");
                      settype("2");
                    }} // Set active state to 'heicToJpg' on click
                  >
                    <p>Preparing</p>
                  </div>
                  {/* Four toggle (HEIC To JPG) */}
                  <div
                    className={`toggle-btn ${
                      activeToggle === "Four" ? "toggle-active" : ""
                    }`}
                    onClick={() => {
                      setActiveToggle("Four");
                      settype("3");
                    }} // Set active state to 'heicToJpg' on click
                  >
                    <p>Dispatched</p>
                  </div>
                  {/* Four toggle (HEIC To JPG) */}
                  <div
                    className={`toggle-btn ${
                      activeToggle === "five" ? "toggle-active" : ""
                    }`}
                    onClick={() => {
                      setActiveToggle("five");
                      settype("4");
                    }} // Set active state to 'heicToJpg' on click
                  >
                    <p>Completed</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">S.No.</div>
                    </th>
                    <th>Order ID</th>
                    <th>Quantity </th>
                    <th>Customer name </th>
                    <th>Delivery At </th>
                    <th>Order Status </th>
                  </tr>
                </thead>
                <tbody>
                  {ordersData?.orders?.map((res, index) => {
                    const dynamicIndex = (page - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>
                          <p className="text-style">{dynamicIndex}</p>
                        </td>
                        <td>
                          <div
                            className="user-role pointer-icon"
                            onClick={() => handlenavigate(res?._id)}
                          >
                            <p>{res?.orderId || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>{res?.order_quantity || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="shop-user-mail">
                            <p>{res?.userId?.name || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="shop-user-mail">
                            <p>
                              {momonet(res?.delivered_at).format(
                                "DD/MM/YYYY"
                              ) || "N/A"}
                            </p>
                          </div>
                        </td>

                        <td>
                          <div
                            className={
                              res?.order_status == 1
                                ? "active"
                                : res?.order_status == 2
                                ? "pending"
                                : res?.order_status == 3
                                ? "pending"
                                : "pending"
                            }
                          >
                            <p>
                            {res?.order_status == 1
                                ? "Accepted"
                                : res?.order_status == 2
                                ? "Preparing"
                                : res?.order_status == 3
                                ? "Dispatched"
                                : "Completed"}
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, ordersData?.totalCount)} of{" "}
                    {ordersData?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={page}
                    totalCount={ordersData?.totalCount}
                    limit={ordersData?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
