import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { DeleteProduct, getAdmindetails, getProducts } from "../redux/Actions/AdminActions";
import Logo from "../Assets/Images/Profile-icon.png";
import { useDispatch } from "react-redux";
import CommonHeader from "../Common/CommonHeader";
import PaginationComponet from "../Components/Pagination";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ProductManagement() {
  const navigate = useNavigate();
  const [productdata, setProductData] = useState([]);
  const [pagi, setPagi] = useState();
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [showd, setshowd] = useState(false);
  const [productId, setProductId] = useState('');

  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      getProducts({
        page: page,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };
    dispatch(getProducts(searchData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setProductData(res?.payload?.data?.data?.data);
          setPagi(res?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [search, limit, page, flag, dispatch]);



  const handledeleteProduct = () => {
    dispatch(DeleteProduct({ productId: productId }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status === 200) {
          toast?.success(res?.payload?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  return (
    <Layout>
      <Container fluid>
        <CommonHeader />
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Product Management</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option>10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search "
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
                <div className="new-profile-btn-action pro-mang-btn">
                  <button onClick={() => navigate("/add-category")}>
                    Add Product Category
                  </button>
                  <button onClick={() => navigate("/add-wine-category")}>
                    Add Wine Category
                  </button>
                  <button onClick={() => navigate("/add-wine-brand")}>
                    Add Wine brand
                  </button>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">S.No.</div>
                    </th>
                    <th>Product Category</th>
                    <th>Title</th>
                    <th>Profile Action </th>
                  </tr>
                </thead>
                <tbody>
                  {productdata?.map((res, index) => {
                    const dynamicIndex = (page - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>
                          <p className="text-style">{dynamicIndex}</p>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>
                              {res?.type === 0
                                ? "Wine Category "
                                : res?.type === 1
                                ? "Wine Brand"
                                : "Product Category" || "N/A"}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>{res?.name || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div
                            className="pending"
                            onClick={() => {
                              setshowd(true);
                              setProductId(res?._id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <p>Remove</p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, pagi?.totalCount)} of{" "}
                    {pagi?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={page}
                    totalCount={pagi?.totalCount}
                    limit={pagi?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to Remove this Product?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handledeleteProduct}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

    </Layout>
  );
}
