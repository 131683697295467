import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import {
  VerifyShop,
  getshopdetail,
  getuserprofile,
} from "../redux/Actions/AdminActions";
import Logo from "../Assets/Images/Profile-icon.png";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { FaFilePdf } from "react-icons/fa";

export default function ShopsProfile() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [userdetail, setUserdetail] = useState({});
  const [Modalpreview, setModalPreview] = useState(false);
  const [Type, settype] = useState("");
  console.log(userdetail, "userdetailuserdetail");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getshopdetail({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status === 200) {
          setUserdetail(res?.payload?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  const handleAction = () => {
    if (Type === "verify") {
      dispatch(VerifyShop({ userId: id, type: "1" })).then((res) => {
        console.log(res);
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          setModalPreview(false);
          navigate("/shops-verification");
        }
      });
    } else if (Type === "reject") {
      dispatch(VerifyShop({ userId: id, type: "2" })).then((res) => {
        console.log(res);
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          setModalPreview(false);
          navigate("/shops-verification");
        }
      });
    }
  };

  return (
    <Layout>
      <Container fluid>
        <div className="user-profile-heading">
          <div
            className="arrow-alt-back"
            onClick={() => navigate("/shops-verification")}
          >
            <img
              src={require("../Assets/Images/arrow_alt_lright.svg").default}
            />
          </div>
          <h2>Shop’s Profile</h2>
        </div>

        <div className="shop-profile-info">
          <div className="shop-profile-pic">
            {userdetail?.profile_image ? (
              <img
                src={`${url}${userdetail?.profile_image}`}
                alt="Profile"
                className="profile-image"
              />
            ) : (
              <img src={Logo} />
            )}
          </div>

          <div className="profile-management-field">
            <div className="user-management-prof-info">
              <h2>Profile info</h2>
              <div className="use-grid-system">
                <div className="input-field-profile">
                  <label>Shop name</label>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={userdetail?.name || "N/A"}
                    />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Email</label>
                  <Form.Group>
                    <Form.Control
                      type="email"
                      value={userdetail?.email || "N/A"}
                    />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>About shop</label>
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={userdetail?.about_me || "N/A"}
                    />
                  </Form.Group>
                </div>

                <div className="input-field-profile">
                  <label>Subscription Plan</label>
                  <Form.Group>
                    <Form.Control type="text" value="N/A" />
                  </Form.Group>
                </div>
              </div>
              <div className="user-management-prof-info">
                <h2>Contact info</h2>
                <div className="use-grid-system">
                  <div className="input-field-profile">
                    <label>Email</label>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        value={userdetail?.email || "N/A"}
                      />
                    </Form.Group>
                  </div>

                  <div className="input-field-profile">
                    <label>Additional Email</label>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        value={userdetail?.email1 || "N/A"}
                      />
                    </Form.Group>
                  </div>

                  <div className="input-field-profile">
                    <label>Phone number</label>
                    <Form.Group>
                      <Form.Control
                        type="email"
                        value={
                          userdetail?.country_code && userdetail?.phone_number
                            ? `${userdetail?.country_code} ${userdetail?.phone_number}`
                            : userdetail?.country_code
                            ? `${userdetail?.country_code}`
                            : userdetail?.phone_number
                            ? `${userdetail?.phone_number}`
                            : "N/A"
                        }
                      />
                    </Form.Group>
                  </div>

                  <div className="input-field-profile">
                    <label>Additional Phone number</label>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        value={
                          userdetail?.add_country_code &&
                          userdetail?.add_phone_number
                            ? `${userdetail?.add_country_code} ${userdetail?.add_phone_number}`
                            : userdetail?.add_country_code
                            ? `${userdetail?.add_country_code}`
                            : userdetail?.add_phone_number
                            ? `${userdetail?.add_phone_number}`
                            : "N/A"
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="input-field-profile">
                    <label>Business address</label>
                    <Form.Group>
                      <Form.Control type="text" value="N/A" />
                    </Form.Group>
                  </div>
                  <div className="input-field-profile">
                    <label>Country</label>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        value={userdetail?.country || "N/A"}
                      />
                    </Form.Group>
                  </div>
                  <div className="input-field-profile">
                    <label>City</label>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        value={userdetail?.city || "N/A"}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-action">
          <div className="profile-btns-left-sides">
            <div className="pro-btns">
              <div className="action-setion">
                <h3>Profile Action</h3>
                <div className="new-profile-btn-action">
                  <button
                    onClick={() => {
                      settype("verify");
                      setModalPreview(true);
                    }}
                  >
                    Verify
                  </button>
                  <button
                    onClick={() => {
                      settype("reject");
                      setModalPreview(true);
                    }}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              <div className="photo-section">
                <h2>Photos</h2>
                <div className="photos-grid">
                  {/* {userdetail?.upload_images?.length > 0 &&
                    userdetail.upload_images.map((img, index) =>
                      img ? (
                        <img
                          key={index}
                          src={`${url}${img}`}
                          alt={`Uploaded ${index + 1}`}
                        />
                      ) : <p>Images not Uploaded yet</p>
                    )} */}

                  {userdetail?.upload_images?.length > 0 ? (
                    userdetail.upload_images.map((img, index) =>
                      img ? (
                        <img
                          key={index}
                          src={`${url}${img}`}
                          alt={`Uploaded ${index + 1}`}
                        />
                      ) : null
                    )
                  ) : (
                    <p>Images not uploaded yet</p>
                  )}

                  
                </div>
              </div>
            </div>
          </div>
          <div className="verify-shops-right-side">
            <div className="shop-verify-heading">
              <h2> Verify shops</h2>
            </div>
            <div className="ein-and-tax">
              <div className="input-field-profile tax-max-width">
                <label>Tax ID</label>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={userdetail?.tax_id || "N/A"}
                  />
                </Form.Group>
              </div>
              <div className="input-field-profile tax-max-width">
                <label>EIN (Employer identification number)</label>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={userdetail?.ein_number || "N/A"}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="proof-and-license">
              <div className="identity-proof">
                <p>Identity proof</p>
                {userdetail?.upload_id ? (
                  userdetail.upload_id.endsWith(".jpg") ||
                  userdetail.upload_id.endsWith(".jpeg") ||
                  userdetail.upload_id.endsWith(".png") ? (
                    <img
                      src={`${url}${userdetail?.upload_id}`}
                      alt="Uploaded File"
                    />
                  ) : userdetail.upload_id.endsWith(".pdf") ? (
                    <a
                      href={`${url}${userdetail?.upload_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={50} color="red" />{" "}
                    </a>
                  ) : (
                    "Unsupported File Type"
                  )
                ) : (
                  "ID Not Found"
                )}
              </div>
              <div className="identity-proof">
                <p>Shop License</p>
              
                {userdetail?.license ? (
                  userdetail.license.endsWith(".jpg") ||
                  userdetail.license.endsWith(".jpeg") ||
                  userdetail.license.endsWith(".png") ? (
                    <img
                      src={`${url}${userdetail?.license}`}
                      alt="Uploaded File"
                    />
                  ) : userdetail.license.endsWith(".pdf") ? (
                    <a
                      href={`${url}${userdetail?.license}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={50} color="red" />{" "}
                    </a>
                  ) : (
                    "Unsupported File Type"
                  )
                ) : (
                  "ID Not Found"
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal show={Modalpreview} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to {Type === "verify" ? "verify" : "reject"}{" "}
            this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn conform-btn" onClick={handleAction}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setModalPreview(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
