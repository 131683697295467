import { createSlice } from "@reduxjs/toolkit";

import { orderManagement } from "../Actions/AdminActions";


export const ordersmgmtSlice = createSlice({
    name: "ordersmgmtSlice",
    initialState:{
        orders: "",
    },
    reducers: {
          
    },

    extraReducers: (builder) => {
        
        builder.addCase(orderManagement.fulfilled, (state, action) => {
            state.orders = action.payload.data.data;
            
        });
    }
})


export default ordersmgmtSlice.reducer;