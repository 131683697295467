import { createSlice } from "@reduxjs/toolkit";

import { shopsverifiactionlisting } from "../Actions/AdminActions";


export const shopsverifiactionSlice = createSlice({
    name: "shopsverifiactionSlice",
    initialState:{
        alldata: "",
    },
    reducers: {
          
    },

    extraReducers: (builder) => {
        
        builder.addCase(shopsverifiactionlisting.fulfilled, (state, action) => {
            state.alldata = action.payload.data.data;
            
        });
    }
    
})


export default shopsverifiactionSlice.reducer;