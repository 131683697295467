import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import {
  DeleteCourse,
  Educationmanagemet,
  getAdmindetails,
} from "../redux/Actions/AdminActions";
import Logo from "../Assets/Images/Profile-icon.png";
import { useDispatch, useSelector } from "react-redux";
import CommonHeader from "../Common/CommonHeader";
import PaginationComponet from "../Components/Pagination";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function CourseManagement() {
  const CourseData = useSelector((state) => state.educationmgmt?.education);
  console.log(CourseData);
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [showd, setshowd] = useState(false);
  const [Courseid, setCouseId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      Educationmanagemet({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };
    dispatch(Educationmanagemet(searchData));
  }, [page, limit, search, flag]);

  const handleDeleteCourse = () => {
    dispatch(DeleteCourse({ courseId: Courseid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status === 200) {
          toast?.success(res?.payload?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  const handlenavgate = (id) => {
    navigate(`/course-view/${id}`);
  };

  return (
    <Layout>
      <Container fluid>
        <CommonHeader />
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Course Management</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">S.No.</div>
                    </th>
                    <th>Course View</th>
                    <th>Course Title </th>
                    <th>Owner name </th>
                    <th>Profile Action</th>
                  </tr>
                </thead>
                <tbody>
                  {CourseData?.courses?.map((res, index) => {
                    const dynamicIndex = (page - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>
                          <p className="text-style">{dynamicIndex}</p>
                        </td>
                        <td>
                          <div className="first-user">
                            <div className="user-profile ">
                              {res?.image ? (
                                <img src={`${url}${res?.image}`} />
                              ) : (
                                <img src={Logo} />
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="user-role pointer-icon">
                            <p onClick={() => handlenavgate(res?._id)}>
                              {" "}
                              {res?.title || "N/A"}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="shop-user-mail">
                            <p>Dummy</p>
                          </div>
                        </td>

                        <td>
                          <div className="pending">
                            <p
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setshowd(true);
                                setCouseId(res?._id);
                              }}
                            >
                              Remove
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, CourseData?.totalCount)} of{" "}
                    {CourseData?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={page}
                    totalCount={CourseData?.totalCount}
                    limit={CourseData?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to Remove this Course?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeleteCourse}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
