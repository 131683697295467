import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { getOrdersDetail } from "../redux/Actions/AdminActions";
import moment from "moment";

export default function OrderDetails() {
  const [ActivityDetails, setActivityDetails] = useState([]);
  const [OrderData, setorderdata] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrdersDetail({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status == 200) {
          setorderdata(res?.payload?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  return (
    <Layout>
      <Container fluid>
        <div className="user-profile-heading ">
          <div
            className="arrow-alt-back"
            onClick={() => navigate("/order-management")}
          >
            <img
              src={require("../Assets/Images/arrow_alt_lright.svg").default}
            />
          </div>
          <h2>Order details</h2>
        </div>

        <div className="shop-profile-info">
          <div className="shop-profile-pic">
            <img
              src={require("../Assets/Images/profile-picture.svg").default}
            />
          </div>
          <div className="profile-management-field">
            <div className="user-management-prof-info">
              <h2>Order info</h2>
              <div className="use-grid-system">
                <div className="input-field-profile">
                  <label>Order ID</label>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={OrderData?.orderId || "N/A"}
                    />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Quantity</label>
                  <Form.Group>
                    <Form.Control
                      type="email"
                      value={OrderData?.order_quantity || "N/A"}
                    />
                  </Form.Group>
                </div>

                <div className="input-field-profile">
                  <label>Customer name</label>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={OrderData?.userId?.name || "N/A"}
                    />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Delivery At</label>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={
                        moment(OrderData?.delivered_at).format("DD/MM/YYYY") ||
                        "N/A"
                      }
                    />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Payment </label>
                  <Form.Group>
                    <Form.Control
                      type="number"
                      value={OrderData?.price || "N/A"}
                    />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Order type</label>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={OrderData?.order_type || "N/A"}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="order-status">
          <div className="input-field-profile">
            <label>Order status</label>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Order Accepted by Shop"
                value={
                  OrderData?.order_status == 1
                    ? "Accepted"
                    : OrderData?.order_status == 2
                    ? "Preparing"
                    : OrderData?.order_status == 3
                    ? "Dispatched"
                    : "Completed"
                }
              />
            </Form.Group>
          </div>
        </div>

        <div className="order-confirmation ">
          <div className="con-firmation-done order-status">
            <div className="steps-and-conf">
              <h2>Shop 1</h2>
              <p>Confirmation Done</p>
            </div>

            <div className="product-all-details">
              <div className="product-name-and-pic">
                <img src={require("../Assets/Images/wine-product.png")} />
                <div className="product-name-quet">
                  <p>St.Lucia Distillers Chairman’s Reserve 1931 Wine</p>
                  <h3>USD 12,333</h3>
                </div>
              </div>
              <div className="product-qty">
                <p>3 Qty</p>
              </div>
            </div>

            <div className="ein-and-tax">
              <div className="input-field-profile tax-max-width">
                <label>Shop name</label>
                <Form.Group>
                  <Form.Control type="text" placeholder="Dummy" />
                </Form.Group>
              </div>
              <div className="input-field-profile tax-max-width">
                <label>Address</label>
                <Form.Group>
                  <Form.Control type="text" placeholder="Dummy" />
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="con-firmation-done order-status">
            <div className="steps-and-conf conf-panding">
              <h2>Shop 2</h2>
              <p>Confirmation Pending</p>
            </div>

            <div className="product-all-details">
              <div className="product-name-and-pic">
                <img src={require("../Assets/Images/wine-product.png")} />
                <div className="product-name-quet">
                  <p>St.Lucia Distillers Chairman’s Reserve 1931 Wine</p>
                  <h3>USD 12,333</h3>
                </div>
              </div>
              <div className="product-qty">
                <p>3 Qty</p>
              </div>
            </div>

            <div className="ein-and-tax">
              <div className="input-field-profile tax-max-width">
                <label>Shop name</label>
                <Form.Group>
                  <Form.Control type="text" placeholder="Dummy" />
                </Form.Group>
              </div>
              <div className="input-field-profile tax-max-width">
                <label>Address</label>
                <Form.Group>
                  <Form.Control type="text" placeholder="Dummy" />
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
