import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import Form from "react-bootstrap/Form";

export default function ReportDetails() {
  const navigate = useNavigate();
  const [ActivityDetails, setActivityDetails] = useState([]);
  const [open, setOpen] = useState(false);
  return (
    <Layout>
      <Container fluid>
        <div className="user-profile-heading ">
          <div className="arrow-alt-back" onClick={() => navigate("/report")}>
            <img
              src={require("../Assets/Images/arrow_alt_lright.svg").default}
            />
          </div>
          <h2>Report Details </h2>
        </div>

        <div className="course-view">
          <div className="left-view-section">
            <div className="outer-view">
              <div className="use-grid-system">
                <div className="input-field-profile">
                  <label>Sender profile</label>
                  <Form.Group>
                    <Form.Control type="number" placeholder="Select" />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Against</label>
                  <Form.Group>
                    <Form.Control type="email" placeholder="Type Here" />
                  </Form.Group>
                </div>

                <div className="input-field-profile">
                  <label>Reason</label>
                  <Form.Group>
                    <Form.Control type="text" placeholder="Post" />
                  </Form.Group>
                </div>
              </div>

              <div className="view-with-border mt-4">
                <div className="view-product">
                  <img src={require("../Assets/Images/product-image.png")} />
                </div>
                <div className="about-this-product">
                  <h2>A virtual evening of smooth jazz</h2>
                </div>

                <div className="comp-certificate mt-3">
                  <p>Location - Gelora Bung Karno Stadium, Jakarta</p>
                  <p>Date - September 1 2024 - Sat 2:00 PM</p>
                </div>
                <div className="comp-certificate mt-2">
                  <p>
                    Price - <b> $49/per ticket </b>
                  </p>
                </div>

                <div className="we-learn">
                  <h2>Description</h2>
                  <p>
                    he material of this course is also covered in my other
                    course about web design and development with HTML5 & CSS3.
                    Scroll to the bottom of this page to check out that course,
                    too! If you're already taking my other course, you already
                    have all it takes to start designing beautiful websites
                    today!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
