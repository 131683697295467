import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import PaginationComponet from "../Components/Pagination";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteUser,
  DowanloadUserxlsv,
  Suspenduser,
  getAdmindetails,
  usermanagement,
} from "../redux/Actions/AdminActions";
import Logo from "../Assets/Images/Profile-icon.png";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import shopOwnerIcon from "../Assets/Images/crown.svg";
import UserIcon from "../Assets/Images/table-user.svg";
import { useNavigate } from "react-router-dom";

import { OverlayTrigger, Popover } from "react-bootstrap";
import CommonHeader from "../Common/CommonHeader";

export default function User() {
  const userdata = useSelector((state) => state.usermgt?.alldata);
  console.log(userdata);
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [activePopoverId, setActivePopoverId] = useState(null);
  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [userid, setUserid] = useState("");
  const [curruntImage, setCurruntImage] = useState("");
  const [admindetails, setAdminDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      usermanagement({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };
    dispatch(usermanagement(searchData));
  }, [page, limit, search, flag]);

  const handleDeleteUSer = () => {
    dispatch(DeleteUser({ id: userid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  useEffect(() => {
    dispatch(getAdmindetails())
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.data?.status === 200) {
          setCurruntImage(response?.payload?.data?.data?.profile_image);
          setAdminDetails(response?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();

  const handleClick = (id) => {
    setClicked(true);
    navigate(`/user-profile/${id}`);
  };

  const ActiveOrDeactivate = (id, status) => {
    dispatch(Suspenduser({ userId: id, type: status }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setActivePopoverId(null); // Close the popover on success
          setflag(!flag);
        }
      })
      .catch((error) => {
        console.log(error, "Error occurred");
      });
  };

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadUserxlsv());
      console.log(response);

      const allData = response?.payload?.data?.data?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const handleTogglePopover = (id) => {
    setActivePopoverId((prevId) => (prevId === id ? null : id));
  };


  const popover = (id) => {
    return (
      <Popover id="popover-basic" className="custom-popover">
        <Popover.Body>
          <p onClick={() => ActiveOrDeactivate(id, 1)}>Active</p>
          <p onClick={() => ActiveOrDeactivate(id, 0)}>In-Active</p>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <Layout>
      <Container fluid>
        <CommonHeader />
      </Container>
      <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap">
                <Row>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Total Users</p>
                          <h2>
                            {userdata?.totalUsers}
                            {/* <span>(+29%)</span> */}
                          </h2>
                          {/* <span className="total-users">Total Users</span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/session-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Total Shops</p>
                          <h2>
                            {userdata?.totalShops}
                            {/* <span> (+18%) </span> */}
                          </h2>
                          {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/padi-user.svg").default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Active Users</p>
                          <h2>
                            {userdata?.activeUsers}

                            {/* <span>(-14%)</span> */}
                          </h2>
                          {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/active-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Inactive Users</p>
                          <h2>
                            {userdata?.inactiveUsers}
                            {/* <span>(+42%)</span> */}
                          </h2>
                          {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/pending-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search User"
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
                <div className="export-box" onClick={fetchDataAndExportToExcel}>
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      {/* <div className="user-checkbox"> */}
                      {/* <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div> */}
                      User
                      {/* </div> */}
                    </th>

                    <th>Role</th>
                    <th>Contact</th>
                    {/* <th>Address</th> */}
                    <th>Country</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userdata?.users?.map((res, index) => {
                    console.log(res);
                    return (
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">
                              <img
                                src={
                                  res?.profile_image
                                    ? `${url}${res?.profile_image}`
                                    : Logo
                                }
                                style={{ borderRadius: 50 }}
                              />
                              <div className="user-id">
                                <p>
                                  {res?.name
                                    ? res?.name
                                    : res?.shop_name || "N/A"}
                                </p>
                                <span>{res.email || "N/A"}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>
                              <span>
                                <img
                                  src={
                                    res?.role === 0 ? UserIcon : shopOwnerIcon
                                  }
                                />
                              </span>
                              {res?.role === 0 ? "User" : "Shop Owner"}
                            </p>
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="cont-num">
                            {" "}
                            {res?.phone_number
                              ? res.country_code
                                ? `${res.country_code} ${res.phone_number}`
                                : `${res.phone_number}`
                              : "N/A"}
                          </div>
                        </td>
                        <td>
                          <div className="cont-num">
                            {" "}
                            {res?.country || "N/A"}
                          </div>
                        </td>
                        <td>
                          <div
                            className={`${
                              res?.is_active === 1 ? "active" : "pending"
                            }`}
                          >
                            <p>
                              {res?.is_active === 1 ? "Active" : "Inactive"}
                            </p>
                          </div>
                        </td>
                        <td>
                          {/* <div className="action"  > */}
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/delete-icon.svg")
                                  .default
                              }
                              onClick={() => {
                                setshowd(true);
                                setUserid(res?._id);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                            {/* 
                            <img
                              src={
                                require("../Assets/Images/view-icon.svg")
                                  .default
                              }
                            /> */}
                            <img
                              src={
                                require("../Assets/Images/view-icon.svg")
                                  .default
                              }
                              alt="View Icon"
                              onClick={() => handleClick(res._id)} // Trigger state and navigation on click
                            />

                            <OverlayTrigger
                              trigger="click"
                              show={activePopoverId === res._id}
                              placement="bottom"
                              overlay={popover(res?._id)}
                              onToggle={() => handleTogglePopover(res._id)}
                              rootClose
                            >
                              <img
                                src={
                                  require("../Assets/Images/options.svg")
                                    .default
                                }
                                alt="Options"
                                style={{ cursor: "pointer" }}
                              />
                            </OverlayTrigger>

                            {/* <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            /> */}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (currentPage - 1) * limit} -{" "}
                    {Math.min(currentPage * limit, userdata?.totalCount)} of{" "}
                    {userdata?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={currentPage}
                    totalCount={userdata?.totalCount}
                    limit={userdata?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Remove this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn conform-btn" onClick={handleDeleteUSer}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setshowd(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
