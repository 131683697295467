import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import {
  Shopsmanagement,
  Suspenduser,
  getAdmindetails,
} from "../redux/Actions/AdminActions";
import Logo from "../Assets/Images/Profile-icon.png";
import { useDispatch, useSelector } from "react-redux";
import CommonHeader from "../Common/CommonHeader";
import PaginationComponet from "../Components/Pagination";
import { toast } from "react-toastify";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function ShopsManagement() {
  const shopsdata = useSelector((state) => state.shopsmgmt?.alldata);
  console.log(shopsdata);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();

  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [activePopoverId, setActivePopoverId] = useState(null);


  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(
      Shopsmanagement({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };
    dispatch(Shopsmanagement(searchData));
  }, [page, limit, search, flag]);


  const handlenavigate = (id) => {
  navigate(`/shops-management-profile/${id}`)
}


const handleTogglePopover = (id) => {
  setActivePopoverId((prevId) => (prevId === id ? null : id));
};


const popover = (id) => {
  return (
    <Popover id="popover-basic" className="custom-popover">
      <Popover.Body>
        <p onClick={() => ActiveOrDeactivate(id, 1)}>Active</p>
        <p onClick={() => ActiveOrDeactivate(id, 0)}>In-Active</p>
      </Popover.Body>
    </Popover>
  );
};




const ActiveOrDeactivate = (id, status) => {
  dispatch(Suspenduser({ userId: id, type: status }))
    .then((res) => {
      console.log(res);
      if (res?.payload?.data?.status === 200) {
        toast.success(res?.payload?.data?.message);
        setActivePopoverId(null); // Close the popover on success
        setflag(!flag);
      }
    })
    .catch((error) => {
      console.log(error, "Error occurred");
    });
};
  return (
    <Layout>
      <Container fluid>
        <CommonHeader />
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Shops Management</h2>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
                {/* <div className="profile-status">
                  <div className="select-box">
                    <Form.Select aria-label="Default select example">
                      <option>Profile Status</option>
                      <option value="active">Dummy</option>
                    </Form.Select>
                  </div>
                </div> */}
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">S.No.</div>
                    </th>
                    <th>Profile picture</th>
                    <th>Shop name </th>
                    <th>Email </th>
                    <th>Profile Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {shopsdata?.users?.map((res, index) => {
                     const dynamicIndex = (page - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>
                          <p className="text-style">{dynamicIndex}</p>
                        </td>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">
                            {res?.profile_image ? (
                                <img src={`${url}${res?.profile_image}`} />
                              ) : (
                                <img src={Logo} />
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div
                            className="user-role pointer-icon"
                            onClick={() =>
                             handlenavigate(res?._id)
                            }
                          >
                            <p>{res?.name || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          <div className="shop-user-mail">
                            <p>{res?.email || "N/A"}</p>
                          </div>
                        </td>

                        <td>
                          <div className={res?.is_active === 1 ? "active" : "pending"}>
                            <p>{res?.is_active === 1 ? "Active" : "In-active"}</p>
                          </div>
                        </td>

                        <td>
                          {/* <div className="action"  > */}
                          <div className="action">
                      
                            <OverlayTrigger
                              trigger="click"
                              show={activePopoverId === res._id}
                              placement="bottom"
                              overlay={popover(res?._id)}
                              onToggle={() => handleTogglePopover(res._id)}
                              rootClose
                            >
                              <img
                                src={
                                  require("../Assets/Images/options.svg")
                                    .default
                                }
                                alt="Options"
                                style={{ cursor: "pointer" }}
                              />
                            </OverlayTrigger>

                        
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p> {" "}
                    Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, shopsdata?.totalCount)} of{" "}
                    {shopsdata?.totalCount} results</p>
                </div>
                <div className="pagination-block">
                <PaginationComponet
                    currentPage={page}
                    totalCount={shopsdata?.totalCount}
                    limit={shopsdata?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>
    </Layout>
  );
}
