import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { getshopProductdetail } from "../redux/Actions/AdminActions";
import Logo from "../Assets/Images/Profile-icon.png";

export default function ProductDetails() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [productdetails, setPRoductDetails] = useState({});

  useEffect(() => {
    dispatch(getshopProductdetail({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status == 200) {
          setPRoductDetails(res?.payload?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);
 

  const handlenavigate = () => {
    navigate(`/shops-products/${id}`)
  }


  return (
    <Layout>
      <Container fluid>
        <div className="user-profile-heading ">
          <div
            className="arrow-alt-back"
            onClick={handlenavigate}
          >
            <img
              src={require("../Assets/Images/arrow_alt_lright.svg").default}
            />
          </div>
          <h2>Product details</h2>
        </div>

        <div className="shop-profile-info">
          <div className="shop-profile-pic">
            {productdetails?.image ? (
              <img src={`${url}${productdetails?.image}`} />
            ) : (
              <img src={Logo} />
            )}
          </div>
          <div className="profile-management-field">
            <div className="user-management-prof-info">
              <h2>Profile info</h2>
              <div className="use-grid-system">
                <div className="input-field-profile">
                  <label>Product category</label>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={productdetails?.product_category?.name || "N/A"}
                    />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Product name</label>
                  <Form.Group>
                    <Form.Control type="email" value={productdetails?.product_name || "N/A"} />
                  </Form.Group>
                </div>

                <div className="input-field-profile">
                  <label>Wine category</label>
                  <Form.Group>
                    <Form.Control type="text" value={productdetails?.wine_category?.name || "N/A"} />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Wine Brand</label>
                  <Form.Group>
                    <Form.Control type="text" value={productdetails?.wine_brand?.name || "N/A"} />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Bottle price</label>
                  <Form.Group>
                    <Form.Control type="text" value={productdetails?.price || "N/A"} />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Quantity</label>
                  <Form.Group>
                    <Form.Control type="text" value={productdetails?.quantity || "N/A"} />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Vintage year</label>
                  <Form.Group>
                    <Form.Control type="text" value={productdetails?.vintage || "N/A"} />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Stock count</label>
                  <Form.Group>
                    <Form.Control type="text" value={productdetails?.count ?? "N/A"}  />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
