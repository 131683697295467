import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import Form from "react-bootstrap/Form";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Addproduct } from "../redux/Actions/AdminActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  name: Yup.string().required("Brand name is required"),
  image: Yup.mixed().required("Icon is required"),
});

export default function AddWineBrand() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ActivityDetails, setActivityDetails] = useState([]);

  const [image, setImage] = useState(null); // To store selected image

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("image", image);
    formData.append("type", "1");

    dispatch(Addproduct(formData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          navigate("/product-management");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Layout>
      <Container fluid>
        <div className="user-profile-heading ">
          <div
            className="arrow-alt-back"
            onClick={() => navigate("/product-management")}
          >
            <img
              src={require("../Assets/Images/arrow_alt_lright.svg").default}
            />
          </div>
          <h2>Add Wine Brand </h2>
        </div>

        <Formik
          initialValues={{
            name: "",
            image: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <Form>
              <div className="order-status add-category-page">
                <div className="input-field-profile">
                  <h2>Add Wine Brand </h2>
                  <label>Brand name</label>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Enter"
                      name="name"
                      onChange={handleChange}
                      value={values?.name}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-message"
                    />
                  </Form.Group>
                </div>
                <div className="input-field-profile">
                  <label>Add icon</label>
                  <div className="add-new-icons">
                    <input
                    className="imgg"
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                        setFieldValue("image", e.target.files[0]);
                      }}
                    />

                    
                    <div className="icons-image-text">
                    <img src={require("../Assets/Images/dummy-image.png")} />
                      <img
                        className="ad-img-fix"
                        src={
                          image
                            ? URL.createObjectURL(image)
                            : null
                        }               
                      />
                     
                      <div className="drop-browser">
                        <p>Drop your imager here, or browse</p>
                        <p>Jpeg, png are allowed </p>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div className="create-btn">
                  <button type="submit" onClick={handleSubmit}>
                    Create
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  );
}
